import React from 'react';
import './WhoAreWe.css';
import { Link } from 'react-router-dom';

function WhoAreWe() {
  return (
    <div className='WhoAreWe_Container'>
        <div className='WhoAreWe_Main'>
            <h1>WHO ARE WE?</h1>
            <p>Your Guide in the Journey of Investment. We provide you with the most accurate data you’ll need to make the most out of the current market situation. From being your advisor to providing help manage your investment we’ll help you navigate through the stock market</p>
            <Link to="/OurTeam" style={{textDecoration:'none'}} width='100%'><button>Our Team&nbsp;&nbsp;<span className='ButtonArrowIcon'><img src={require('../../Assets/icons8-right-arrow-50.png')} width="100%"/></span></button></Link>
        </div>
    </div>
  )
}

export default WhoAreWe;