import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import './GetInTouch.css';

function GetInTouch() {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);


  const Email_func = (e) => {
    e.preventDefault();

    const serviceID = 'service_y4qn2bm';
    const templateID = 'template_6hidxp1';
    const publicKey = '4kBNPCh11udem9u5z';

    const templateParams = {
      first_name: firstname,
      last_name: lastname,
      email_message: emailMessage,
      phone_number: phoneNumber,
      email_id: email,
    };

    emailjs
      .send(serviceID, templateID, templateParams, publicKey)
      .then((response) => {
        console.log('Email send successfully!', response);
        setShowSuccessMessage(true);
        setTimeout(() => {
            setShowSuccessMessage(false);
          }, 5000);
        setIsFormSubmitted(true);
      })
      .catch((error) => {
        console.log('Error sending email.', error);
      });
  };

  useEffect(() => {
    if (isFormSubmitted) {
      // Reset the form fields after the state has been updated
      console.log("use effect getting triggered");
      document.getElementById("contactUsForm").reset();
      setFirstname('');
      setLastname('');
      setEmail('');
      setPhoneNumber('');
      setEmailMessage('');
      setIsFormSubmitted(false);
    }
  }, [isFormSubmitted]);

  return (
    <div className='GetInTouch_Container'>
      <div className='GetInTouch_Main'>
        <div className='GetInTouch_Form'>
          <h1>Contact us</h1>
          <p>Our team of experts would love to guide you!</p>
          <form id="contactUsForm" onSubmit={Email_func}>
            <div className='GetInTouchFormBoxes' id='GetInTouchFormBoxes_name'>
              <div className='GetInTouchFormBoxesName'>
                <label>First name</label>
                <input
                  placeholder='First name'
                  name='first_name'
                  onChange={(e) => {
                    setFirstname(e.target.value);
                  }}
                />
              </div>
              <div className='GetInTouchFormBoxesName'>
                <label>Last name</label>
                <input
                  placeholder='Last name'
                  name='last_name'
                  onChange={(e) => {
                    setLastname(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className='GetInTouchFormBoxes'>
              <label>Email</label>
              <input
                type='email'
                placeholder='Email'
                name='email_id'
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className='GetInTouchFormBoxes'>
              <label>Phone number</label>
              <input
                placeholder='Phone number'
                name='phone_number'
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
            </div>
            <div className='GetInTouchFormBoxes'>
              <label>Message</label>
              <textarea
                placeholder='Enter your message here'
                rows='4'
                name='email_message'
                onChange={(e) => {
                  setEmailMessage(e.target.value);
                }}
              ></textarea>
            </div>
            <div className='GetInTouchFormBoxes'>
              <button type='submit' style={{ cursor: 'pointer' }}
              className={showSuccessMessage ? 'success' : ''}>
                {showSuccessMessage ? 'Message Sent!' : 'Send message'}
              </button>
            </div>
          </form>
        </div>
        <div className='GetInTouch_Map'>
          <div>
            <img src={require('../../Assets/map.png')} width={'100%'} alt="map" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetInTouch;
