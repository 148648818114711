import React, { useState, useEffect } from 'react';
import './Testimonials.css';

function Testimonials() {
  const [reviewDetails, setReviewDetails] = useState([
    { id: 0, reviewerName: "Bibha Jha", star: "⭐⭐⭐⭐⭐", review: "This company provides good advice and invest your money in equity and market to make maximum profit... the team putting all efforts to grow the profit" },
    { id: 1, reviewerName: "Saurav Anand", star: "⭐⭐⭐⭐⭐", review: "I have got satisfactory  return through them.They have pretty good knowledge of stock market  and had provided me valuable advice. So ,If you want to invest in share market ,I highly recommend  to contact them." },
    { id: 2, reviewerName: "Rishav Kesan", star: "⭐⭐⭐⭐⭐", review: "A wonderful platform to invest your money.They have good command and knowledge so as to how to multiply the money safely on shares market. A highly recommended platform." },
    { id: 3, reviewerName: "Udaykant Jha", star: "⭐⭐⭐⭐⭐", review: "This company provides you better advice and investment plan for your future planning...i even put some portion of my retirement money invest in company it's provides maximum profit" },
    { id: 4, reviewerName: "Madhumita Jha", star: "⭐⭐⭐⭐⭐", review: "A good place if you want to start investing in share market. they have deep and good knowledge about stock market. highly recommended." },
    { id: 5, reviewerName: "Kumar Anand", star: "⭐⭐⭐⭐⭐", review: "Best place to consult about your investment...A genuine suggestions is given where to invest your money and handling your account and how to promote your money in future endeavours." }
  ]);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 5000); // Change the interval (in milliseconds) as needed

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviewDetails.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + reviewDetails.length) % reviewDetails.length);
  };

  return (
    <div className='Testimonials_Container'>
      <div className='Testimonials_Main'>
        <p>DON'T JUST TAKE OUR WORDS <br /> SEE WHAT OUR CUSTOMERS SAY ABOUT US</p>
        <div className='review_content'>
          <div className='review_card'>
         
            <h1>{reviewDetails[currentIndex]["reviewerName"]} </h1><hr/>
            <p>{reviewDetails[currentIndex]["review"]}</p>
            <span style={{ fontSize: '2rem', padding: '1rem' }}>{reviewDetails[currentIndex]["star"]}</span>
      
          </div>
        </div>
      </div>
      <div className='Testimonials_toggle_icon'>
        <img onClick={handlePrev} src={require('../../Assets/icons8-sort-left-100.png')} width={"24px"} alt="Previous" />
        <img onClick={handleNext} src={require('../../Assets/icons8-sort-right-100.png')} width={"24px"} alt="Next" />
      </div>
    </div>
  );
}

export default Testimonials;
