import React from 'react';
import WhoAreWeImg from '../../Assets/Who_are_we.png';
import './AboutOurCompany.css';
import { Link } from 'react-router-dom';

function AboutOurCompany() {
  return (
    <>
    <div className='AboutOurCompany_Container'>
        <div className='AboutOurCompany_Main'>
            <div className='AboutOurCompany_Main_Left'>
                <h1>ABOUT OUR COMPANY</h1>
                <p>At BullvsBear we believe in creating relationships that not only boost our client’s profits but are very well committed to delivering the best investment strategies. Our aim is “GROW with INDIA”. We perceive the potential the market holds and its vast capabilities and we are determined to find a path that suits our client’s needs the best. With our expertise and analytics, we can make the journey of your financial investments an easy ride.</p>
                <Link to="/AboutOurCompany" style={{textDecoration:'none', width:'100%'}}><button>Discover Our Company &nbsp;&nbsp;<span className='ButtonArrowIcon'><img src={require('../../Assets/icons8-right-arrow-50.png')} width="100%"/></span></button></Link>
            </div>
            <div className='AboutOurCompany_Main_Right'>
                <img src={WhoAreWeImg} alt='Indian Stock Market' width={"100%"}/>
            </div>
        </div>
    </div>
    </>
  )
}

export default AboutOurCompany;