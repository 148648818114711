import React, { useEffect, useState } from 'react';
import whytoinvestinimage from '../../Assets/whytoinvestin.svg';
import whytoinvestinimagein from '../../Assets/whytoinvestin1.png';
import './WhyInvest.css';
import { Link } from 'react-router-dom';

function WhyInvest() {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const element = document.querySelector('.WhyInvest_Main_Right');

      if (element) {
        const elementPosition = element.offsetTop;
        const windowHeight = window.innerHeight;

        if (scrollPosition > elementPosition - windowHeight + 100) {
          setAnimate(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className='WhyInvest_Container'>
      <div className='WhyInvest_Main'>
        <div className='WhyInvest_Main_Left'>
          <img src={whytoinvestinimagein} alt='Indian Stock Market' width={'90%'} />
        </div>
        <div className={`WhyInvest_Main_Right ${animate ? 'animate' : ''}`}>
          <h1>WHY INVEST IN THE INDIAN STOCK MARKET?</h1>
          <p>
            Investing in the Indian stock market may be the best thing you do, as the global market tremble, the Indian
            stock market shows exceptional growth factors. There is accelerating economic growth due to a growing middle
            class and young population, and the potential for new companies to emerge has made the Indian stock market
            an investor's best bet. The finest part is that it is supported by exceptional political stability and
            numerous government initiatives, making it the safest investment possible.
          </p>

          <Link to="/DownloadCompanyProfile" style={{width:'100%',textDecoration:'none'}}><button>Download Company's Profile &nbsp;&nbsp;<span className='ButtonArrowIcon'><img src={require('../../Assets/icons8-right-arrow-50.png')} width="100%"/></span></button></Link>
        </div>
      </div>
    </div>
  );
}

export default WhyInvest;
