import React from 'react';
import './OurServicesPage.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

function Insurance_Advisory() {
  return (
    <>
    <div className='Navbar_Holder'>
        <Navbar/>
    </div>
    <div className='OurServicesPage_Container'>
        <div className='OurServicesPage_Header' id='Insurance_Advisory_bg'>
            <h1>Insurance Advisory</h1>
        </div>
        <div className="Services_Body">
        <p style={{textAlign:"justify"}}>
          Insurance is a contract according to which the insurer (the insurance
          company) will provide financial coverage (Sum assured) against
          contingencies for an amount (premium) given by an individual. There
          are various insurance options available in the market. Under this
          scheme, we help you choose the best possible insurance scheme for you
          according to your current and future financial needs. Our experts
          completely analyze your profile before suggesting the right insurance
          scheme for you. There are various kinds of insurance services that we
          provide, categorized as General and Life Insurance.
        </p>
        </div>
        <div className='Services'>
        <h2>1. Under Life Insurance, we cover:</h2>
        <ul>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>Term Life Insurance</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>Whole Life Insurance</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>Endowment Plans</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>Unit-Linked Insurance Plans</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>Child Plans</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>Pension Plans</li>
        </ul>
        <h2>2. Under General Insurance, we cover:</h2>
        <ul>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>Health Insurance</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>Motor Insurance</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>Home Insurance</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>Travel Insurance</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>Fire Insurance</li>
        </ul>
        <p style={{marginLeft:'-2%',marginTop:'2rem', textAlign:"justify"}}>
          We are associated with HDFC Life, MAX Health Insurance, pnpMetLife,
          SBI Life, Kotak Life Insurance, HDFC ERGO, ICICI Lombard, Aditya Birla
          Capital, TATA AIG Insurance, BAJAJ Allianz, and a few others to help
          you choose the most suited insurance to fulfill your needs.
        </p>
        <h2>3. Under this scheme, we offer:</h2>
        <ul>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>We suggest insurance according to clients' needs and preferences.</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>Proper guidance will be provided.</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>We analyze all insurance options to offer you the best.</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>24*7 assisted calls to resolve all your queries.</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>Hassle-free services.</li>
        </ul>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default Insurance_Advisory;