import React from 'react';
import './OurServicesPage.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

function Mutual_Funds_Advisory_Scheme() {
  return (
    <>
    <div className='Navbar_Holder'>
        <Navbar/>
    </div>
    <div className='OurServicesPage_Container'>
        <div className='OurServicesPage_Header' id='Mutual_Funds_Advisory_Scheme_bg'>
            <h1>Mutual Funds Advisory Scheme</h1>
        </div>
        <div className="Services_Body">
        <p>
          A mutual fund is a type of investment instrument in which several
          individuals combine their money in order to generate returns on their
          capital over time. The investment's gains (or losses) are shared
          jointly by the investors in proportion to their commitment to the
          fund. The fact that mutual funds provide an easy approach to
          diversifying across several securities is one of their key benefits.
          In addition to perhaps providing larger returns than if the investor
          had invested in a single stock or bond, this can help to lower total
          investment risk.
        </p>
        </div>
        <div className='Services'>
            <h2>Under this scheme, we offer:</h2>
            <ul>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>
                    The suggestion of mutual funds according to clients' needs and
                    preferences.
                </li>
                <li style={{listStyleType:'disc',marginLeft:'4%'}}>LUM-SUM and SIP system is available.</li>
                <li style={{listStyleType:'disc',marginLeft:'4%'}}>Proper guidance will be provided.</li>
                <li style={{listStyleType:'disc',marginLeft:'4%'}}>
                    We study every mutual fund strategy and profit growth and suggest to
                    our client.
                </li>
                <li style={{listStyleType:'disc',marginLeft:'4%'}}>24*7 assisted call support.</li>
                <li style={{listStyleType:'disc',marginLeft:'4%'}}>Hassle-free services.</li>
            </ul>
        </div>
    </div>
    <Footer/>
    </>
  )
}

export default Mutual_Funds_Advisory_Scheme;