import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AboutOurCompanyPage from './Components/AboutOurCompanyPage/AboutOurCompanyPage';
import OurTeam from './Components/OurTeam/OurTeam';
import Annual_Investment_Scheme from './Components/OurServicesPage/Annual_Investment_Scheme';
import Insurance_Advisory from './Components/OurServicesPage/Insurance_Advisory';
import Investment_Advisory_Services from './Components/OurServicesPage/Investment_Advisory_Services';
import Mutual_Funds_Advisory_Scheme from './Components/OurServicesPage/Mutual_Funds_Advisory_Scheme';
import Portfolio_Scheme from './Components/OurServicesPage/Portfolio_Scheme';
import Trading_Account_Scheme from './Components/OurServicesPage/Trading_Account_Scheme';
import ScrollToTop from './Components/ScrollToTop';
import DownloadCompanyProfile from './Components/DownloadCompanyProfile/DownloadCompanyProfile';
import OurAnalysisAndBlogs from './Components/OurAnalysisAndBlogs/OurAnalysisAndBlogs';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <ScrollToTop/>
    <Routes>
      <Route path="/" element={<App/>}></Route>
      <Route path='/DownloadCompanyProfile' element={<DownloadCompanyProfile/>}></Route>
      <Route path="/AboutOurCompany" element={<AboutOurCompanyPage/>}></Route>
      <Route path="/OurTeam" element={<OurTeam/>}></Route>
      <Route path="/Annual_Investment_Scheme" element={<Annual_Investment_Scheme/>}></Route>
      <Route path="/Insurance_Advisory" element={<Insurance_Advisory/>}></Route>
      <Route path="/Investment_Advisory_Services" element={<Investment_Advisory_Services/>}></Route>
      <Route path="/Mutual_Funds_Advisory_Scheme" element={<Mutual_Funds_Advisory_Scheme/>}></Route>
      <Route path="/Portfolio_Scheme" element={<Portfolio_Scheme/>}></Route>
      <Route path="/Trading_Account_Scheme" element={<Trading_Account_Scheme/>}></Route>
      <Route path="/OurAnalysisAndBlogs" element={<OurAnalysisAndBlogs/>}></Route>
    </Routes>
  </BrowserRouter>
  // <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
