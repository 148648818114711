import React from 'react';
import './DownloadCompanyProfile.css';
import Navbar from '../Navbar/Navbar';

function DownloadCompanyProfile() {
  return (
    <>
        <div className='Navbar_Holder'>
            <Navbar/>
        </div>
        <div className='DownloadCompanyProfile_Container'>
            Available for Download Soon
        </div>
    </>
  )
}

export default DownloadCompanyProfile;