import React from 'react';
import './OurServicesPage.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

function Portfolio_Scheme() {
  return (
    <>
    <div className='Navbar_Holder'>
        <Navbar/>
    </div>
    <div className='OurServicesPage_Container'>
        <div className='OurServicesPage_Header' id='Portfolio_Scheme_bg'>
            <h1>Portfolio Scheme</h1>
        </div>
        <div className="Services_Body">
            <p>
            A stock portfolio is a collection of multiple stocks from various
            companies or various segments that allows you to invest in a
            diversified manner to minimize the risk factor associated. A portfolio
            might comprise equities from a variety of firms, industries, and
            geographic locations, as well as bonds, ETFs, and mutual funds. You
            can become a more resilient investor by assembling a diverse
            portfolio. We provide you with comprehensive resources and analysis
            that will help you make informed decisions when it comes to buying,
            selling, or holding stocks. Our experienced analysts regularly monitor
            and evaluate market trends, so you don't have to.
            </p>
        </div>
        <div className="Services">  
            <h2>Under this scheme, we offer:</h2>
            <ul>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>
                A customized selection of stocks/sectors clients want to invest in.
            </li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>Profit as per our portfolio: 15-20% annually.</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>
                The portfolio is built by taking care of proper risk management.
            </li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>We study every stock suggested in the portfolio.</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>
                Proper guidance will be provided on buying and selling of shares.
            </li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>24*7 support will be provided.</li>
            <li style={{listStyleType:'disc',marginLeft:'4%'}}>
                Diverse portfolio to mitigate the risk by including debt funds
                stocks.
            </li>
            </ul>
        </div>  
    </div>
    <Footer/>
    </>
  )
}

export default Portfolio_Scheme;