import React from 'react';
import Navbar from '../Navbar/Navbar';
import { motion } from 'framer-motion';
import './Hero.css';

const textVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 1.5,
      delayChildren: 0.5,
      staggerDirection: 1,
      staggerChildren: 0.1,
    },
  },
};

const charVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

function Hero() {
  return (
    <div className='Hero_Container'>
      <Navbar props={'transparent'} />
      <div className='Hero_Main'>
        <motion.h1
          variants={textVariants}
          initial='hidden'
          animate='visible'
          className='Hero_Main_h1'
        >
          {['GROW WITH INDIA,', 'INVEST WITH', 'BULL VS BEAR'].map((line, index) => (
            <div key={index}>
              {line.split('').map((char, charIndex) => (
                <motion.span key={charIndex} variants={charVariants}>
                  {char}
                </motion.span>
              ))}
              <br />
            </div>
          ))}
        </motion.h1>
      </div>
    </div>
  );
}

export default Hero;
