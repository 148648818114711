import React from 'react';
import './App.css';
import Hero from './Components/Hero/Hero';
import WhyInvest from './Components/WhyInvest/WhyInvest';
import OurClients from './Components/OurClients/OurClients';
import AboutOurCompany from './Components/AboutOurCompany/AboutOurCompany';
import WhoAreWe from './Components/WhoAreWe/WhoAreWe';
import OurServices from './Components/OurServices/OurServices';
import OurAnalysisAndBlogs from './Components/OurAnalysisAndBlogs/OurAnalysisAndBlogs';
import Testimonials from './Components/Testimonials/Testimonials';
import Footer from './Components/Footer/Footer';
import GetInTouch from './Components/GetInTouch/GetInTouch';

function App() {
  return (
    <div className="App">
      <Hero/>
      <WhyInvest/>
      <OurClients/>
      <AboutOurCompany/>
      <WhoAreWe/>
      <OurServices/>
      {/* <OurAnalysisAndBlogs/> */}
      <Testimonials/>
      <GetInTouch/>
      <Footer/>
    </div>
  );
}

export default App;
