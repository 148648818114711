import React from 'react';
import './OurTeam.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

function OurTeam() {

  return (
    <>
    <div className='Navbar_Holder'>
        <Navbar/>
    </div>
    <div className='Ourteam_container'>
        <div className='Ourteam_Banner_Header'>
            <h1>We revolutionzed the discount broking model in India. Now, we are breaking ground with our technology.</h1>
        </div>
        <div className='Ourteam_header'>
            <h1>OUR TEAM</h1> <hr />
            <p>No initiative can be led by an individual. Let’s meet these incredible minds, responsible for creating these custom-made plans for you so as to achieve your financial goals.</p>
        </div>
            <div className='Ourteam_main'>
                <div className='Ourteam_card'>
                    <div className='Ourteam_card_image'>
                        <img src={require('../../Assets/Akshay.jpg')} style={{border:'5px solid #000D39'}}/>
                        <div className='Ourteam_Social'>
                            {/* <img src={require('../../Assets/icons8-facebook-48.png')}/> */}
                            {/* <img src={require('../../Assets/icons8-instagram-48.png')}/> */}
                            <img src={require('../../Assets/icons8-linkedin-48.png')}/>
                        </div>
                    </div>
                    <div className='Ourteam_card_content'>
                        <p>Akshay began his journey as an entrepreneur when he founded BullvsBear in December 2022. As a B. Com graduate, working as a CA his journey is full of ups and downs. He believed to take all his learnings and curate something great. As a founder, he has a very real experience with the stock market. He started early with a nick towards investment, but that’s when he realized the importance of research and experience. He acquired accreditation from the National Institute of Securities Markets (NISM) to gain a competitive edge in the market. As it is rightfully said failure is a step closer to success and that’s what Akshay represent</p>
                    </div>
                </div>
                <div className='Ourteam_card invert' id='Ourteam_card_invert'>
                    <div className='Ourteam_card_content'>
                        <p>Ayush is a core part of BullvsBear, having an amazing business ideology with his Law degree from a prestigious National Law University he has been an integral part of the company’s establishment. Having run his family business with proficiency, he knows what goes into making a company stand out. He has expertise in law ethics and financial market understanding which contributes to the company’s various innovative schemes.</p>
                    </div>
                    <div className='Ourteam_card_image_right'>
                        
                        <img src={require('../../Assets/Ayush.jpg')} style={{border:'5px solid #000D39'}}/>
                        
                        
                        <div className='Ourteam_Social'>
                            {/* <img src={require('../../Assets/icons8-facebook-48.png')}/> */}
                            {/* <img src={require('../../Assets/icons8-instagram-48.png')}/> */}
                            <img src={require('../../Assets/icons8-linkedin-48.png')}/>
                        </div>
                    </div>
                </div>
                <div className='Ourteam_card'>
                    <div className='Ourteam_card_image'>
                        <img src={require('../../Assets/Arpan.jpg')} style={{border:'5px solid #000D39'}}/>
                        <div className='Ourteam_Social'>
                            {/* <img src={require('../../Assets/icons8-facebook-48.png')}/> */}
                            {/* <img src={require('../../Assets/icons8-instagram-48.png')}/> */}
                            <img src={require('../../Assets/icons8-linkedin-48.png')}/>
                        </div>
                    </div>
                    <div className='Ourteam_card_content'>
                        <p>Arpan being an operations officer, has the company flow going, as operations are a very crucial part to drive a company smoothly. His experience as a CA and his understanding towards the financial market has contributed a lot when it comes to managing the operations of an investment company. He leverages his experience as an Operations Director in his family business and takes into consideration that the company follows all regularities and that the client's reviews are of utmost satisfaction.</p>
                    </div>
                </div>
                {/* <div className='Ourteam_card' id='Ourteam_card_invert'>
                    <div className='Ourteam_card_content'>
                        <p>Neha has a master’s in Commerce and a very well understanding of the stock market. She also has done the National Institute of Securities Markets (NISM) certification which makes her a great research analyst. Neha has been part of the team since the beginning and with her accurate analysis reports has contributed a lot to making great profits for our clients.</p>
                    </div>
                    <div className='Ourteam_card_image_right'>
                        <img src='https://bloximages.newyork1.vip.townnews.com/theadvocate.com/content/tncms/assets/v3/editorial/4/09/409072f8-49c7-5ed9-8498-5ed79e80b204/5751d2c81ba96.image.jpg'/>
                    </div>
                </div> */}
            </div>
        </div>
    <Footer/>
    </>
  )
}

export default OurTeam;