import React from 'react';
import './AboutOurCompanyPage.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

function AboutOurCompanyPage() {
  return (
    <>
    <div className='Navbar_Holder'>
        <Navbar/>
    </div>
    <div className='Aboutourcompanypage_Container'>
        <div className='Aboutourcompanypage_Header'>
            <h1>We revolutionzed the discount broking model in India. Now, we are breaking ground with our technology.</h1>
        </div>
        <div className='Aboutourcompanypage_Aboutourcompany'>
            <div className='Aboutourcompanypage_Aboutourcompany_title'>
                <h1>ABOUT OUR COMPANY</h1>
            </div>
            <div className='Aboutourcompanypage_Aboutourcompany_content'>
                <p>At BullvsBear, our aim is to “GROW with INDIA”. We understand the vast potential the Indian stock market holds and we plan to capitalize the most of it by determining the right path that suits our client’s needs the best. At our company, we firmly believe that our expertise and analytical capabilities to grasp the market and find the true potential assets for our client’s portfolio is our distinctive quality. We are here to make the journey of your financial investments an easy ride for you, to guide you through ins and outs of the stock market and help you grow with India, with BullvsBear.</p>
            </div>
        </div>
        <div className='Ourdna_container'>
            <div className='Ourdna_title'>
                <h1>OUR DNA</h1>
            </div>
            <div className='Ourdna_box'>
                <div className='Ourdna_box_title'>
                    <h1>Rendering Analysis into Investment</h1>
                </div>
                <div className='Ourdna_box_content'>
                    <p>When guiding your journey, we believe in statistical accuracy to provide you with the best outcomes. We strive to reach the most accurate results by formulating market statistics to our advantage.</p>
                </div>
            </div>
            <div className='Ourdna_box'>
                <div className='Ourdna_box_title'>
                    <h1>Tailored portfolios</h1>
                </div>
                <div className='Ourdna_box_content'>
                    <p>We intend to offer personalized investment strategies to meet clients’ investment objectives. The value of money for every individual is different, and at BullvsBear we understand the different requirements for financial independence each client holds. With our personalized investment plans, we try to cater to your specifications.</p>
                </div>
            </div>
            <div className='Ourdna_box'>
                <div className='Ourdna_box_title'>
                    <h1>Risk Management</h1>
                </div>
                <div className='Ourdna_box_content'>
                    <p>With a diversified portfolio, we consider in the risk factors attached to any investments. The stock market as you know it does not always act to our advantage but we do keep the risk strategies in mind while helping you invest. We try to opt for multiple investment options which allow us to perfectly manage the risk associated.</p>
                </div>
            </div>
            <div className='Ourdna_box'>
                <div className='Ourdna_box_title'>
                    <h1>Transparency is our focal point</h1>
                </div>
                <div className='Ourdna_box_content'>
                    <p>With assisted calls on your queries, and utmost transparency on our analysis. Our goal is to be transparent with our strategies for your investments. We believe in establishing great relationships with our clients, and we value the amount of trust our clients put in us while investing money, this is we are always here for you for all your queries</p>
                </div>
            </div>
            <div className='Ourdna_box' style={{borderBottom:'none'}}>
                <div className='Ourdna_box_title'>
                    <h1>Renovating our strategies</h1>
                </div>
                <div className='Ourdna_box_content'>
                    <p>We believe in continuous learning from the market and adapting to all feedback in order to improve our ideology. Our strategies are completely based on the learning we analyze from the market but we also aim to improvise on any feedback received from our clients. We strive to provide you with the best services.</p>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    </>
  )
}

export default AboutOurCompanyPage;