import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='Footer'>
        <div className='Footer_Up'>
            <div className='Footer_logo'>
                <div className='footerimage'><img src={require('../../Assets/footerlogo.png')} alt='logo'/></div>
                <h1>BULL vs BEAR</h1>
            </div>
            <div className='Footer_Company_Address'>
                {/* <div className='Footer_Company'>
                    <h1>Company</h1>
                    <Link to='/'><p>Home</p></Link>
                    <p>Services</p>
                    <p>Research</p>
                </div> */}
                <div className='Footer_Address'>
                    <h1>Address</h1>
                    <p>2nd Floor, Pihu Bhawan<br></br>Opp AN College, Boring Road<br></br>Patna, Bihar-800013</p>
                </div>
            </div>
            <div className='Footer_third'>
                <h1>Follow us on</h1>
                <div style={{width:'100%',textAlign:'left',display:'flex',gap:'10px',marginTop:'1rem'}}>
                <Link to="https://www.linkedin.com/in/bullvsbear-co-in/" target="_blank" rel="noopener noreferrer">
                    <img src={require('../../Assets/icons8-linkedin-100.png')}  width={"32px"}/>
                </Link>
                <Link to="https://www.instagram.com/bullvsbear.ig/" target="_blank" rel="noopener noreferrer">
                  <img src={require('../../Assets/icons8-instagram-circle-100.png')}  width={"32px"}/>
                  </Link>
                </div>
            </div>
        </div>
        <div className='Footer_Down'>
            <div className='Footer_Container'>
                <div className='Footer_First'>
                    <p>Copyright © 2023. Bull vs Bear Pvt Ltd. All Rights Reserved.</p>
                </div>
                <div className='Footer_Second'>
                    <p>Cin No: U66190BR2023PTC062644</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer;