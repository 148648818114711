import React from 'react';
import './OurServicesPage.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

function Trading_Account_Scheme() {
  return (
    <>
    <div className='Navbar_Holder'>
        <Navbar/>
    </div>
    <div className='OurServicesPage_Container'>
        <div className='OurServicesPage_Header' id='Trading_Account_Scheme_bg'>
            <h1>Trading Account Scheme</h1>
        </div>
        <div className="Services_Body">
            <p>
                A trading account is your first step towards investment. It is an account with a financial institution that allows you to buy or sell securities like stocks, options, bonds, and futures. At BullvsBear, we help our clients open a trading account with our channel partner SMC GLOBAL.
            </p>
        </div>
        <div className='Services'>    
            <h2>Under this scheme, we provide:</h2>
            <ul>
                <li style={{listStyleType:'disc',marginLeft:'4%'}}>Best trading account appearance-wise</li>
                <li style={{listStyleType:'disc',marginLeft:'4%'}}>Extremely instigated and detail-oriented</li>
                <li style={{listStyleType:'disc',marginLeft:'4%'}}>Full-house brokerage firm</li>
                <li style={{listStyleType:'disc',marginLeft:'4%'}}>Margin facility</li>
                <li style={{listStyleType:'disc',marginLeft:'4%'}}>24*7 assisted calls</li>
                <li style={{listStyleType:'disc',marginLeft:'4%'}}>Hassle-free account opening process</li>
                <li style={{listStyleType:'disc',marginLeft:'4%'}}>Low brokerage</li>
                <li style={{listStyleType:'disc',marginLeft:'4%'}}>User-friendly interface</li>
            </ul>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default Trading_Account_Scheme;