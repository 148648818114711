import React from 'react';
import './OurServicesPage.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

function Investment_Advisory_Services() {
  return (
    <>
    <div className='Navbar_Holder'>
        <Navbar/>
    </div>
    <div className='OurServicesPage_Container'>
        <div className='OurServicesPage_Header' id='Investment_Advisory_Services_bg'>
            <h1>Investment Advisory Services</h1>
        </div>
        <div className="Services_Body">
            <p>
            As an investment advising firm, we work closely with customers to
            understand their risk tolerance, financial goals, and preferences.
            Accordingly, we help them navigate through various investment options.
            There are four services that we provide under this category:
            </p>
        </div>
        <div className="Services">
          <ul>
            <li>
              <h2>1. Banknifty Options Trading Scheme:</h2>
              <p style={{textAlign:"justify"}}>
                Banknifty Options Trading is based on the Bank Nifty Index,
                which is a benchmark used to track the performance of the
                banking sector in India. Under this investment scheme, you can
                trade options contracts based on Bank Nifty Index. Under this
                scheme, We provide daily assisted calls, 3-5 times a day to keep
                you updated with our current analysis.
              </p>
              <ul>
                <li>Our analysis guarantees 95% trading accuracy.</li>
                <li>20-25% profit marked per month.</li>
                <li>24*7 support for your queries.</li>
                <li>
                  Proper entry, exit, and stop-loss will be provided along with
                  the chart analysis.
                </li>
              
              <p>
                Subscription fees: Rs 2,000 per month
                <br />
                Minimum capital required: Rs 25,000
                <br />
                High Risk and Highly Volatile
              </p>
              </ul>
            </li>
            <li>
              <h2>2. Nifty Options Trading Scheme:</h2>
              <p style={{textAlign:"justify"}}>
                This trading derivative is based on the Nifty 50 Index which is
                monitored by the National Stock Exchange (NSE), which is the
                benchmark index of the NSE. Nifty options are contracts in that
                the buyer has the right to buy or sell at an agreed price called
                strike price before the expiration date. Under this scheme, We
                provide daily assisted calls, 2-4 times a day to keep you
                updated with our current analysis.
              </p>
              <ul>
                <li>Our analysis guarantees 95% trading accuracy.</li>
                <li>20-25% profit marked per month.</li>
                <li>24*7 support for your queries.</li>
                <li>
                  Proper entry, exit, and stop-loss will be provided along with
                  the chart analysis.
                </li>
              <p>
                Subscription fees: Rs 2,000 per month
                <br />
                Minimum capital required: Rs 20,000
                <br />
                High Risk and Moderate Volatile
              </p>
              </ul>

            </li>
            <li>
              <h2>3. Stocks Options Trading Scheme:</h2>
              <p style={{textAlign:"justify"}}> 
                Stocks Options trading is an investment derivative which allows
                the buyer to sell or buy any specific stocks and the strike
                price. In an Options contract, you don’t need to pay the full
                price for the security. The buyer has the option to not sell or
                acquire at a defined pricing or date. Under this scheme, We
                provide daily assisted calls, 2-4 times a day to keep you
                updated with our current analysis.
              </p>
              <ul>
                <li>Our analysis guarantees 95% trading accuracy.</li>
                <li>20-25% profit marked per month.</li>
                <li>24*7 support for your queries.</li>
                <li>
                  Proper entry, exit, and stop-loss will be provided along with
                  the chart analysis.
                </li>
              <p>
                Subscription fees: Rs 2,000 per month
                <br />
                Minimum capital required: Rs 35,000
                <br />
                Moderate Risk and Low Volatile
              </p>
              </ul>

            </li>
            <li>
              <h2>4. Stock Futures Trading Scheme:</h2>
              <p style={{textAlign:"justify"}}>
                This scheme involves a contract, stating an agreement between
                two parties on buying or selling any specific stock, and this
                contract has an expiration date. These agreements are called
                stock futures contracts. Under this scheme, We provide daily
                assisted calls, 2-4 times a day to keep you updated with our
                current analysis.
              </p>
              <ul>
                <li>Our analysis guarantees 95% trading accuracy.</li>
                <li>20-25% profit marked per month.</li>
                <li>24*7 support for your queries.</li>
                <li>
                  Proper entry, exit, and stop-loss will be provided along with
                  the chart analysis.
                </li>
              <p>
                Subscription fees: Rs 2,000 per month
                <br />
                Minimum capital required: Rs 1,50,000
                <br />
                High Risk and Highly Volatile
              </p>
              </ul>
            </li>
          </ul>
        </div>
        </div>
    <Footer/>
    </>
  )
}

export default Investment_Advisory_Services;