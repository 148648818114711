import React from 'react';
import './OurServicesPage.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

function Annual_Investment_Scheme() {
  return (
    <>
    <div className='Navbar_Holder'>
        <Navbar/>
    </div>
    <div className='OurServicesPage_Container'>
        <div className='OurServicesPage_Header' id='Annual_Investment_Scheme_bg'>
            <h1>Annual Investment Scheme</h1>
        </div>
        <div className="Services_Body" style={{textAlign:"justify"}}>

        <p>
          In this scheme, we invest your money according to your risk capacity (if the client wants high risk/moderate risk/ low risk) we keep your preference to our priority and outline your preference with our expert analysis to help you get the most out of your investment plan. We study the market for you and park your money in the right stock, boosting your investment.
          In this scheme, we build a customized portfolio according to your risk appetite and the profit growth you want.
            We prioritize your financial goals with our statistical analysis.
        </p>
        </div>
        <div className="Services">
            <h2>Under this scheme:</h2>
            <ul>
                <li style={{listStyleType:'disc'}}> The client has to open a trading account with us.</li>
                <li style={{listStyleType:'disc'}}> In case of losses, it will be borne by the company.</li>
                <li style={{listStyleType:'disc'}}> All the profit and loss will be calculated at the end of the year, and not in between.</li>
                <li style={{listStyleType:'disc'}}> Full transparency will be maintained. The client will be notified of every buying and selling of shares.</li>
                <li style={{listStyleType:'disc'}}> A quarterly report will be provided of your investment growth.</li>
                <li style={{listStyleType:'disc'}}> Lump sum investment or SIP (Systematic Investment Plan) investment (every month/quarter/half-yearly) scheme also available under this scheme.</li>
                <li style={{listStyleType:'disc'}}> Lock-in period: 1 year.</li>
                <li style={{listStyleType:'disc'}}> Guaranteed profit: 20% p.a.</li>
                <li style={{listStyleType:'disc'}}> 24*7 assisted calls on your queries.</li>
                <li>Charges: *Annually.
                <ul>
                    <li>On capital: Till 1 lac, the client will get guaranteed 20% on invested amount. Excess of 20%: Company charges.</li>
                    <li>On capital: 1 Lac - 5 Lac, the client will get a guaranteed 20% of invested amount. Excess of 20% profit will be shared on a 1:9 ratio.</li>
                    <li>On capital: More than 5 Lac, the client will get 20% guaranteed of invested amount. Excess of 20% profit will be shared on a 1:1 ratio.</li>
                </ul>
                </li>
            </ul>
        </div>
    </div>
    <Footer/>
    </>
  )
}

export default Annual_Investment_Scheme;