import React from 'react';
import './OurClients.css';

function OurClients() {
  return (
    <div className='OurClients_Container'>
        <div className='OurClients_Header'>
            <h1>OUR INSURANCE PARTNERS</h1>
            <p>We are proud of our commitment and long-term relationship that we have built with many of our clients. We continue to work together and earn their trust for many years to come.</p>
        </div>
        <div className="marquee-wrapper">
            <div className="container">
                <div className="marquee-block">
                    <div className="marquee-inner to-left">
                        <span>
                            {/* <div className="marquee-item">
                                <p className="text-white"><img src={require('../../Assets/bajaj.png')}/></p>
                            </div> */}
                            <div className="marquee-item">
                                <p className="text-white"><img src={require('../../Assets/digit.png')}/></p>
                            </div>
                            <div className="marquee-item">
                                <p className="text-white"><img src={require('../../Assets/icici.png')}/></p>
                            </div>
                            <div className="marquee-item">
                                <p className="text-white"><img src={require('../../Assets/max.png')}/></p>
                            </div>
                            
                        </span>
                        <span>
                            <div className="marquee-item">
                                <p className="text-white"><img src={require('../../Assets/bajaj.png')}/></p>
                            </div>
                            <div className="marquee-item">
                                <p className="text-white"><img src={require('../../Assets/digit.png')}/></p>
                            </div>
                            <div className="marquee-item">
                                <p className="text-white"><img src={require('../../Assets/icici.png')}/></p>
                            </div>
                            <div className="marquee-item">
                                <p className="text-white"><img src={require('../../Assets/max.png')}/></p>
                            </div>
                            {/* <div className="marquee-item">
                                <p className="text-white"><img src={require('../../Assets/care.png')}/></p>
                            </div> */}

                        </span>
                    </div>
                </div>
            </div>
        </div>  
    </div>
  )
}

export default OurClients;