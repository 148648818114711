import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './OurServices.css';
import { Link } from 'react-router-dom';

const responsive = {
    desktop: {
      breakpoint: { max: 1980, min: 1380 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1380, min: 912 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 912, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    wide: {
        breakpoint: { max: 3000, min: 1980 },
      items: 4,
      slidesToSlide: 4 // optional
    }

};

function OurServices() {
  return (
    <div className='OurServices_Container'>
        <div className='OurServices_Main'>
            <div className='OurServices_Main_Header'>
                <h1>SERVICES WE PROVIDE</h1>
                <p>We intend to provide you with the highest level of services for you to make your investment journey with us seamless</p>
            </div>
            <div className='OurServices_Main_Carousel'>
                <Carousel
                    // centerMode={true}
                    swipeable={true}
                    containerClass="carousel-container"
                    responsive={responsive}
                    showDots={true}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                >
                    <div className='ServicesWeProvide_Carousel_Card'>
                        <div className='Carasouel_card_blackStripdiv'><div className='Carasouel_card_blackStrip'></div></div>
                        <div className='Carasouel_card_image'>
                            <img src={require('../../Assets/Investment_advisory.png')} alt='INVESTMENT ADVISORY SERVICES'/>
                        </div>
                        <h1>INVESTMENT ADVISORY SERVICES</h1>
                        <p>As an investment advising firm, we work closely with customers to understand their risk tolerance, financial goals, and preferences. Accordingly, we help them navigate through various investment options. There are seven services that we provide under this category</p>
                        <Link to="/Investment_Advisory_Services" style={{textDecoration:'none'}}><button>Know More</button></Link>                            
                    </div>

                    <div className='ServicesWeProvide_Carousel_Card'>
                        <div className='Carasouel_card_blackStripdiv'><div className='Carasouel_card_blackStrip'></div></div>
                        <div className='Carasouel_card_image'>
                            <img src={require('../../Assets/Annual_investment_scheme.png')} alt='INVESTMENT ADVISORY SERVICES'/>
                        </div>
                        <h1>ANNUAL INVESTMENT SCHEME</h1>
                        <p>In this scheme, we invest your money according to your risk capacity (if the client 
                            wants high risk/moderate risk/ low risk) we keep your preference to our priority and 
                            outline your preference with our expert analysis to help you get the most out of your 
                            investment plan.
                        </p>
                        <Link to="/Annual_Investment_Scheme" style={{textDecoration:'none'}}><button>Know More</button></Link>                            
                    </div>

                    <div className='ServicesWeProvide_Carousel_Card'>
                        <div className='Carasouel_card_blackStripdiv'><div className='Carasouel_card_blackStrip'></div></div>
                        <div className='Carasouel_card_image'>
                            <img src={require('../../Assets/Portfolio_scheme.png')} alt='INVESTMENT ADVISORY SERVICES'/>
                        </div>
                        <h1>PORTFOLIO SCHEME</h1>
                        <p>A stock portfolio is a collection of multiple stocks from various companies or various 
                            segments that allows you to invest in a diversified manner to minimise the risk factor 
                            associated.
                        </p>
                        <Link to="/Portfolio_Scheme" style={{textDecoration:'none'}}><button>Know More</button></Link>                            
                    </div>

                    <div className='ServicesWeProvide_Carousel_Card'>
                        <div className='Carasouel_card_blackStripdiv'><div className='Carasouel_card_blackStrip'></div></div>
                        <div className='Carasouel_card_image'>
                            <img src={require('../../Assets/Mutual_advisory_scheme.png')} alt='INVESTMENT ADVISORY SERVICES'/>
                        </div>
                        <h1>MUTUAL FUNDS ADVISORY SCHEME</h1>
                        <p>A mutual fund is a type of investment instrument in which several individuals 
                        combine their money in order to generate returns on their capital over time. The 
                        investment's gains (or losses) are shared jointly by the investors in proportion to 
                        their commitment to the fund.
                        </p>
                        <Link to="/Mutual_Funds_Advisory_Scheme" style={{textDecoration:'none'}}><button>Know More</button></Link>                            
                    </div>

                    <div className='ServicesWeProvide_Carousel_Card'>
                        <div className='Carasouel_card_blackStripdiv'><div className='Carasouel_card_blackStrip'></div></div>
                        <div className='Carasouel_card_image'>
                            <img src={require('../../Assets/insurance_advisory.png')} alt='INVESTMENT ADVISORY SERVICES'/>
                        </div>
                        <h1>INSURANCE ADVISORY</h1>
                        <p>Insurance is a contract according to which the insurer (the insurance company) will provide 
                        financial coverage (Sum assured) against contingencies for an amount (premium) given by 
                        an individual.
                        </p>
                        <Link to="/Insurance_Advisory" style={{textDecoration:'none'}}><button>Know More</button></Link>                            
                    </div>

                    <div className='ServicesWeProvide_Carousel_Card'>
                        <div className='Carasouel_card_blackStripdiv'><div className='Carasouel_card_blackStrip'></div></div>
                        <div className='Carasouel_card_image'>
                            <img src={require('../../Assets/Trading_account_scheme.png')} alt='INVESTMENT ADVISORY SERVICES'/>
                        </div>
                        <h1>TRADING ACCOUNT SCHEME</h1>
                        <p>A trading account is your first step towards investment. It is an account with a 
                        financial institution which allows you to buy or sell securities like stocks, options, 
                        bonds and futures.
                        </p>
                        <Link to="/Trading_Account_Scheme" style={{textDecoration:'none'}}><button>Know More</button></Link>                            
                    </div>
                </Carousel>
            </div>
        </div>
    </div>
  )
}

export default OurServices;