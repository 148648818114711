import React from 'react';
import './OurAnalysisAndBlogs.css';

function OurAnalysisAndBlogs() {
  return (
    <div className='OurAnalysisAndBlogs_Container'>
        <div className='OurAnalysisAndBlogs_Main'>
            <div className='OurAnalysisAndBlogs_Main_Header'>
                <h1>OUR ANALYSIS AND BLOGS</h1>
                <p></p>
            </div>
            <div className='OurAnalysisAndBlogs_Main_Card_Container'>
                <div className='OurAnalysisAndBlogs_Main_Card'>
                  <div className='OurAnalysisAndBlogs_Main_Card_Image'>

                  </div>
                  <div className='OurAnalysisAndBlogs_Main_Card_Heading'>

                  </div>
                </div>
                <div className='OurAnalysisAndBlogs_Main_Card'>
                  <div className='OurAnalysisAndBlogs_Main_Card_Image'>

                  </div>
                  <div className='OurAnalysisAndBlogs_Main_Card_Heading'>

                  </div>
                </div>
                <div className='OurAnalysisAndBlogs_Main_Card'>
                  <div className='OurAnalysisAndBlogs_Main_Card_Image'>

                  </div>
                  <div className='OurAnalysisAndBlogs_Main_Card_Heading'>

                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OurAnalysisAndBlogs;