import React, { useEffect, useRef } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

function Navbar() {
  const navTriggerRef = useRef(null);
  const mainNavRef = useRef(null);

  useEffect(() => {
    // Function to close the dropdown when clicking outside the navbar
    const handleClickOutside = (event) => {
      if (
        mainNavRef.current &&
        !mainNavRef.current.contains(event.target) &&
        navTriggerRef.current &&
        !navTriggerRef.current.contains(event.target)
      ) {
        // Close the dropdown by unchecking the navTrigger checkbox
        document.getElementById('navTrigger').checked = false;
      }
    };

    // Add the event listener to the document body
    document.body.addEventListener('click', handleClickOutside);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleServicesClick = (event) => {
    // Prevent the default behavior of the link
    event.preventDefault();

    // Toggle the checkbox to open/close the dropdown
    const navTriggerCheckbox = document.getElementById('navTrigger');
    if (navTriggerCheckbox) {
      navTriggerCheckbox.checked = !navTriggerCheckbox.checked;
    }
  };

  return (
    <header className='header' id='head'>
      <div className='container'>
        <div className='header_left'>
        <Link to='/' className='logo'>
          <img src={require('../../Assets/footerlogo.png')} width={"60px"}/>
          BULL vs BEAR
        </Link>
        </div>
        <div className='header_right' ref={mainNavRef}>
          <label htmlFor="navTrigger" className='nav_icon'><i className="fa fa-bars"></i></label>
          <input id='navTrigger' type='checkbox' name=''ref={navTriggerRef}/>
          <nav className='main_nav'>
            <ul id='hh'>
              <li><Link to='/'>Home</Link></li>
              <li><Link to='/AboutOurCompany'>About us</Link></li>
              <li><div className="navServicesTab" style={{color:'white', padding:'5px 15px'}}>Services</div>
                <i className="fa fa-sort-desc"></i>
                <ul>
                  <li><Link to="/Investment_Advisory_Services" style={{textDecoration:'none'}}>Investment Advisory Services</Link></li>
                  <li><Link to="/Annual_Investment_Scheme" style={{textDecoration:'none'}}>Annual Investment Scheme</Link></li>
                  <li><Link to="/Portfolio_Scheme" style={{textDecoration:'none'}}>Portfolio Scheme</Link></li>
                  <li><Link to="/Mutual_Funds_Advisory_Scheme" style={{textDecoration:'none'}}>Mutual Funds Advisory Scheme</Link></li>
                  <li><Link to="/Insurance_Advisory" style={{textDecoration:'none'}}>Insurance Advisory</Link></li>
                  <li><Link to="/Trading_Account_Scheme" style={{textDecoration:'none'}}>Trading Account Scheme</Link></li>
                </ul>
              </li>
              <li><Link to='/OurTeam'>Our Team</Link></li>
              <li><Link to='/OurAnalysisAndBlogs'>Blogs</Link></li>
              <li id='companyProfileitem'><Link to='/DownloadCompanyProfile'>Download Company's Profile</Link></li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Navbar;